import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateFilter } from "slices/thunks";
import {
  assignmentStates,
  invoiceStates,
} from "Components/constants/assignmentStates";

const TaskListGlobalFilter = () => {
  const { currentUser: user, isTaskListSuccess } = useSelector((state) => ({
    currentUser: state.Users.currentUser,
    isTaskListSuccess: state.Tasks.isTaskListSuccess,
  }));

  const dispatch = useDispatch();

  let filter = JSON.parse(localStorage.getItem("filter")) ?? {};

  if (filter?.bookingStatus == null || filter?.bookingStatus?.length == 0) {
    let newFilter = {};

    newFilter.bookingStatus = [
      {
        value: 1,
        label: "New",
      },
      {
        value: 2,
        label: "Planned",
      },
      {
        value: 3,
        label: "Booked",
      },
      {
        value: 4,
        label: "Execution",
      },
      {
        value: 5,
        label: "QA",
      },
      {
        value: 6,
        label: "Report ready for delivery",
      },
      {
        value: 7,
        label: "Report delivered",
      },
      {
        value: 8,
        label: "Presentation planned",
      },
    ];

    localStorage.setItem("filter", JSON.stringify(newFilter));

    // Update filter
    filter = newFilter;
  }

  if (filter?.invoiceStatus == null || filter?.invoiceStatus?.length == 0) {
    // Map all invoice states
    filter.invoiceStatus = invoiceStates.map((x) => ({
      label: x.name,
      value: x.id,
    }));

    // Default invoice states for SD and I
    if (user.primaryRole == "SD" || user.primaryRole == "I") {
      filter.invoiceStatus = [
        {
          value: 1,
          label: "Awaiting invoicer",
        },
        {
          value: 2,
          label: "Awaiting BDM",
        },
        {
          value: 4,
          label: "Awaiting invoicing",
        },
      ];
    }

    localStorage.setItem("filter", JSON.stringify(filter));
  }

  // Init filter on first load
  useEffect(() => {
    dispatch(updateFilter(filter));
  }, [isTaskListSuccess]);

  function handleTaskStatus(filterType, status) {
    if (filterType == 1) filter.bookingStatus = status;

    if (filterType == 2) filter.invoiceStatus = status;

    localStorage.setItem("filter", JSON.stringify(filter));
    dispatch(updateFilter(filter));
  }

  const options = assignmentStates.map((x) => ({
    label: x.name,
    value: x.id,
  }));

  const invoiceOptions = invoiceStates.map((x) => ({
    label: x.name,
    value: x.id,
  }));

  return (
    <React.Fragment>
      <div className="col-xxl-12 col-sm-12">
        <div className="input-light">
          <Select
            defaultValue={filter.bookingStatus}
            onChange={(x) => {
              handleTaskStatus(1, x);
            }}
            options={options}
            isMulti
            placeholder="Filter..."
          />
        </div>
      </div>

      {(user.primaryRole == "SD" || user.primaryRole == "I") && (
        <div className="col-xxl-12 col-sm-12">
          <div className="input-light">
            <Select
              defaultValue={filter.invoiceStatus}
              onChange={(x) => {
                handleTaskStatus(2, x);
              }}
              options={invoiceOptions}
              isMulti
              placeholder="Invoice filter..."
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { TaskListGlobalFilter };
