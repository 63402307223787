import React, { useEffect, useMemo } from "react";
import TableContainer from "../../../Components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";

import { Customer, Services, Status, InvoiceStatusLabel } from "./TaskListCol";

// Formik
import { Link } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { getTaskList, updateFilter } from "../../../slices/thunks";

import * as moment from "moment";

const AllTasks = () => {
  const dispatch = useDispatch();

  const { taskList } = useSelector((state) => ({
    taskList: state.Tasks.taskList,
  }));

  useEffect(() => {
    if (taskList.length == 0) dispatch(getTaskList());
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      filterable: true,
      Cell: (cellProps) => {
        return (
          <React.Fragment>
            <div className="d-flex">
              <div className="flex-grow-1 tasks_name">
                <Link
                  to={`/assignments/${cellProps.row?.original?.assignmentId}/${cellProps.row?.original?.serviceId}`}
                >
                  {cellProps.row?.original.name
                    ? `${cellProps.row?.original.name}`
                    : `${cellProps.row?.original.assignment.name} (${cellProps.row?.original.assignment.agreementNumber})`}
                </Link>
                <div>
                  <Status value={cellProps.row?.original.status} />{" "}
                  <InvoiceStatusLabel
                    value={cellProps.row?.original.invoiceStatus}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Service",
      accessor: "service.name",
      filterable: true,
      Cell: (cellProps) => {
        return <Services {...cellProps} />;
      },
    },
    {
      Header: "Agreement No.",
      accessor: "assignment.agreementNumber",
      filterable: true,
      Cell: (cellProps) => {
        return <React.Fragment>{cellProps.value}</React.Fragment>;
      },
    },
    {
      Header: "Customer",
      accessor: "assignment.customer.name",
      filterable: true,
      Cell: (cellProps) => {
        return <Customer {...cellProps} />;
      },
    },
    {
      Header: "Responsible",
      accessor: "assignment.responsibleBDM.name",
      filterable: true,
      Cell: (cellProps) => {
        return <React.Fragment>{cellProps.value}</React.Fragment>;
      },
    },
    /*     
    {
      Header: "Status",
      accessor: "status",
      filterable: false,
      Cell: (cellProps) => {
        return <Status {...cellProps} />;
      },
    },
    {
      Header: "Invoice status",
      accessor: "invoiceStatus",
      filterable: false,
      Cell: (cellProps) => {
        return <InvoiceStatusLabel {...cellProps} />;
      },
    }, 
    */
    {
      Header: "Period",
      accessor: "startDate",
      filterable: false,
      Cell: (cellProps) => {
        return (
          <>
            {cellProps.value
              ? moment(cellProps.row?.original?.startDate).format("DD. MMM Y")
              : ""}{" "}
            -{" "}
            {cellProps.value
              ? moment(cellProps.row?.original?.endDate).format("DD. MMM Y")
              : ""}
          </>
        );
      },
    },
  ]);

  return (
    <React.Fragment>
      <div className="row">
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">All Assignments</h5>
              </div>
            </div>

            <div className="card-body pt-0">
              <TableContainer
                columns={columns}
                data={taskList || []}
                isGlobalFilter={true}
                customPageSize={10}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
                trClass=""
                isTaskListFilter={true}
              />
              <ToastContainer closeButton={false} limit={1} />
            </div>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default AllTasks;
