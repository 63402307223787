import React, { useEffect, useState } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import AzureAD from "Components/Common/AzureAD";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";

function App() {
  const [authorized, setAuthorized] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    var account = accounts[0];
    if (account) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          setAuthorized(true);
        });
    }
  }, [accounts]);

  return authorized ? <Route /> : <AzureAD />;
}

export default App;
