import { createSlice } from "@reduxjs/toolkit";
import { getServices, upsertService } from "./thunk";
export const initialState = {
  serviceList: [],
  isServiceUpdated: false,
};

const ServicesSlice = createSlice({
  name: "ServicesSlice",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.serviceList = action.payload;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.error = action.payload?.error || null;
    });
    builder.addCase(upsertService.fulfilled, (state, action) => {
      const updatedService = action.payload;

      // Find the service in the serviceList array
      const existingService = state.serviceList.find(
        (service) => service.id === updatedService.id
      );

      if (existingService) {
        existingService.active = updatedService.active;
      } else {
        state.serviceList.push(updatedService);
      }

      state.isServiceUpdated = true;
    });
    builder.addCase(upsertService.rejected, (state, action) => {
      state.isServiceUpdated = false;
    });
  },
});

export default ServicesSlice.reducer;
