export const google = {
  API_KEY: "",
  CLIENT_ID: "",
  SECRET: "",
};

export const facebook = {
  APP_ID: "",
};

export const api = {
  API_URL: process.env.REACT_APP_API_URL,
  SUB_KEY: process.env.REACT_APP_API_SUB_KEY,
};
