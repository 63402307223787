import { useField } from "formik";
import React from "react";
import { Label } from "reactstrap";
import Flatpickr from "react-flatpickr";

const CustomDatePicker = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  delete field.onChange;
  delete field.onBlur;

  return (
    <>
      <Label className="form-label">{label}</Label>
      <Flatpickr
        {...field}
        {...props}
        options={{
          minDate: "today",
          weekNumbers: true,
        }}
        className={
          meta.touched && meta.error
            ? "input-error form-control"
            : "form-control"
        }
        placeholder="Select a date"
      />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </>
  );
};
export default CustomDatePicker;
