import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { logoutUser } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";
import { useMsal } from "@azure/msal-react";

const Logout = (props) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  if (isUserLogout) {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
