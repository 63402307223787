import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import AllUsers from "./AllServices";

const ServiceList = () => {
  document.title = "Service List | ImproView";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Service List"
            pageTitle="Services"
            link="/services"
          />
          <AllUsers />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceList;
