const assignmentStates = [
  {
    id: 0,
    name: "Potential",
  },
  {
    id: 1,
    name: "New",
  },
  {
    id: 2,
    name: "Planned",
  },
  {
    id: 3,
    name: "Booked",
  },
  {
    id: 4,
    name: "Execution",
  },
  {
    id: 5,
    name: "QA",
  },
  {
    id: 6,
    name: "Report ready for delivery",
  },
  {
    id: 7,
    name: "Report delivered",
  },
  {
    id: 8,
    name: "Presentation planned",
  },
  {
    id: 11,
    name: "Completed",
  },
  {
    id: 12,
    name: "Lost",
  },
  {
    id: 13,
    name: "Archived",
  },
];

const invoiceStates = [
  {
    id: 1,
    name: "Awaiting invoicer",
  },
  {
    id: 2,
    name: "Awaiting BDM",
  },
  {
    id: 3,
    name: "Invoice details completed",
  },
  {
    id: 4,
    name: "Awaiting invoicing",
  },
  {
    id: 5,
    name: "Invoiced",
  },
];

export { assignmentStates, invoiceStates };
