import BreadCrumb from "Components/Common/BreadCrumb";
import TableContainer from "Components/Common/TableContainer";
import { FormikProvider, useFormik, Form } from "formik";
import CustomSelect from "pages/Tasks/TaskDetails/Form/CustomSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { upsertUser } from "slices/users/thunk";

const UserInfo = () => {
  const { user, serviceList } = useSelector((state: any) => ({
    user: state.Users.user,
    serviceList: state.Services.serviceList,
  }));

  const [selectedServicesCheckBox, setSelectedServicesCheckBox] = useState([]);

  useEffect(() => {
    if (user?.services) {
      setSelectedServicesCheckBox(user.services);
    }
  }, [user, serviceList]);

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: user ?? {},
    onSubmit: (values) => {
      let userPayload = JSON.parse(JSON.stringify(values));

      if (userPayload.primaryRole === "") {
        userPayload.primaryRole = null;
      }

      userPayload.services = selectedServicesCheckBox;
      dispatch(upsertUser(userPayload));
    },
  });

  // Select all checkbox
  const checkedAll = (checkbox) => {
    if (checkbox.checked) {
      const selectAll = serviceList.map((x) => ({
        id: x.id,
      }));
      setSelectedServicesCheckBox(selectAll);
    } else {
      setSelectedServicesCheckBox([]);
    }
  };

  // Select single checkbox
  const selectCheckbox = (checkbox) => {
    let clonedSelectedServicesCheckBox = JSON.parse(
      JSON.stringify(selectedServicesCheckBox)
    );

    if (checkbox) {
      if (checkbox.checked) {
        // Add to selected
        clonedSelectedServicesCheckBox.push({
          id: checkbox.value,
        });
      } else {
        // Remove from selected
        clonedSelectedServicesCheckBox.splice(
          clonedSelectedServicesCheckBox.findIndex((x) => {
            return x.id === checkbox.value;
          }),
          1
        );
      }
    }
    setSelectedServicesCheckBox(clonedSelectedServicesCheckBox);
  };

  // Update services
  const updateUserServices = () => {
    console.log("services", selectedServicesCheckBox);
  };

  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          id="checkBoxAll"
          className="form-check-input"
          onClick={(e) => checkedAll(e.target)}
        />
      ),
      width: 20,
      Cell: (cellProps) => {
        return (
          <input
            type="checkbox"
            className="taskCheckBox form-check-input"
            value={cellProps.row?.original?.id}
            checked={selectedServicesCheckBox.some(
              (x) => x.id == cellProps.row?.original?.id
            )}
            onChange={(e) => selectCheckbox(e.target)}
          />
        );
      },
      id: "#",
    },
    {
      Header: "Name",
      accessor: "name",
      filterable: false,
      Cell: (cellProps) => {
        return <React.Fragment>{cellProps.value}</React.Fragment>;
      },
    },
  ];

  return (
    <React.Fragment>
      <BreadCrumb title={`${user?.name}`} pageTitle="Users" link="/users" />

      <Row>
        <Col xxl={12}>
          {user ? (
            <FormikProvider value={validation}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <CustomSelect label="Role" name="primaryRole">
                            <option value="">No access</option>
                            <option value="SD">Service Delivery</option>
                            <option value="M">Manager</option>
                            <option value="BDM">
                              Business Development Manager
                            </option>
                            <option value="SA">Security Advisor </option>
                            <option value="I">Invoicer </option>
                          </CustomSelect>
                        </div>
                      </Col>
                    </Row>

                    {validation.values.primaryRole == "M" && (
                      <div className="card">
                        <div className="card-header border-0">
                          <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">
                              Services ({selectedServicesCheckBox.length})
                            </h5>
                          </div>
                        </div>

                        <div className="card-body pt-0">
                          <TableContainer
                            columns={columns}
                            data={serviceList || []}
                            isGlobalFilter={false}
                            customPageSize={10}
                            divClass="table-responsive table-card mb-3"
                            tableClass="align-middle table-nowrap mb-0"
                            theadClass="table-light table-nowrap"
                            thClass="table-light text-muted"
                            trClass=""
                            isTaskListFilter={false}
                          />
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>

                <Col lg={12} className="mb-3">
                  <div className="hstack gap-2 justify-content-end">
                    <Link to="/tasks/" className="btn-light btn btn-secondary">
                      Cancel
                    </Link>
                    <Button type="submit" className="btn btn-success">
                      Update
                    </Button>
                  </div>
                </Col>
              </Form>
            </FormikProvider>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default UserInfo;
