import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthProtected = (props) => {
  const user = useSelector((state) => state.Users.currentUser);
  if (user) {
    if (props.role == null || props.role == user.primaryRole) {
      return <>{props.children}</>;
    }
  }
  return <></>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
