import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { TaskStatus, InvoiceStatus } from "./Enum";

const handleValidDate = (date) => {
  if (date == null) return;
  const date1 = moment(new Date(date)).format("DD. MMM Y HH:mm");
  return date1;
};

const Customer = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Services = (cell) => {
  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="flex-grow-1 tasks_name">{cell.value}</div>
      </div>
    </React.Fragment>
  );
};

const Tasks = (cell) => {
  return (
    <React.Fragment>
      <div className="d-flex">
        <div className="flex-grow-1 tasks_name">{cell.value}</div>
        <div className="flex-shrink-0 ms-4">
          <ul className="list-inline tasks-list-menu mb-0">
            <li className="list-inline-item">
              <Link to={`/assignments/${cell.value?.id}`}>
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="#">
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <a
                className="remove-item-btn"
                data-bs-toggle="modal"
                href="#deleteOrder"
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

const CreateBy = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const AssignedTo = (cell) => {
  return (
    <React.Fragment>
      <div className="avatar-group">
        {cell.value.map((item, index) => (
          <Link
            key={index}
            to="/users"
            className="avatar-group-item"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-placement="top"
            title="Alexis"
          >
            <img src={item.img} alt="" className="rounded-circle avatar-xxs" />
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};

const CustomDate = (cell) => {
  return <React.Fragment>{handleValidDate(cell.value)}</React.Fragment>;
};

const Status = (cell) => {
  return (
    <React.Fragment>
      {cell.value === TaskStatus.Potential ? (
        <span className="badge badge-soft-secondary text-uppercase">
          Potential
        </span>
      ) : null}

      {cell.value === TaskStatus.New ? (
        <span className="badge badge-soft-info text-uppercase">New</span>
      ) : null}

      {cell.value === TaskStatus.Booked ? (
        <span className="badge badge-soft-success text-uppercase">Boooked</span>
      ) : null}

      {cell.value === TaskStatus.Planned ? (
        <span className="badge badge-soft-success text-uppercase">Planned</span>
      ) : null}

      {cell.value === TaskStatus.Execution ? (
        <span className="badge badge-soft-success text-uppercase">
          Execution
        </span>
      ) : null}

      {cell.value === TaskStatus.QA ? (
        <span className="badge badge-soft-success text-uppercase">QA</span>
      ) : null}

      {cell.value === TaskStatus.ReportReadyForDelivery ? (
        <span className="badge badge-soft-success text-uppercase">
          Report Ready For Delivery
        </span>
      ) : null}

      {cell.value === TaskStatus.ReportDelivered ? (
        <span className="badge badge-soft-success text-uppercase">
          Report delivered
        </span>
      ) : null}

      {cell.value === TaskStatus.PresentationPlanned ? (
        <span className="badge badge-soft-success text-uppercase">
          Presentation Planned
        </span>
      ) : null}

      {cell.value === TaskStatus.Completed ? (
        <span className="badge badge-soft-success text-uppercase">
          Completed
        </span>
      ) : null}

      {cell.value === TaskStatus.Lost ? (
        <span className="badge badge-soft-danger text-uppercase">Lost</span>
      ) : null}

      {cell.value === TaskStatus.Archived ? (
        <span className="badge badge-soft-danger text-uppercase">Archived</span>
      ) : null}
    </React.Fragment>
  );
};

const InvoiceStatusLabel = (cell) => {
  return (
    <React.Fragment>
      {cell.value === InvoiceStatus.AwaitingInvoicer ? (
        <span className="badge badge-soft-info text-uppercase">
          Awaiting Invoicer
        </span>
      ) : null}

      {cell.value === InvoiceStatus.AwaitingBDM ? (
        <span className="badge badge-soft-info text-uppercase">
          Awaiting BDM
        </span>
      ) : null}

      {cell.value === InvoiceStatus.InvoiceDetailsCompleted ? (
        <span className="badge badge-soft-warning text-uppercase">
          Invoice Details Completed
        </span>
      ) : null}

      {cell.value === InvoiceStatus.AwaitingInvoicing ? (
        <span className="badge badge-soft-info text-uppercase">
          Awaiting Invoicing
        </span>
      ) : null}

      {cell.value === InvoiceStatus.Invoiced ? (
        <span className="badge badge-soft-success text-uppercase">
          Invoiced
        </span>
      ) : null}
    </React.Fragment>
  );
};

export {
  Customer,
  Services,
  Tasks,
  CreateBy,
  AssignedTo,
  CustomDate,
  Status,
  InvoiceStatusLabel,
};
