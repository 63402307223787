import React from "react";
import { Navigate } from "react-router-dom";
import Logout from "../pages/Authentication/Logout";
import TaskDetails from "../pages/Tasks/TaskDetails";
import TaskList from "../pages/Tasks/TaskList";
import UserList from "pages/Users/UserList";
import UserDetails from "pages/Users/UserDetails";
import ServiceList from "pages/Services/ServiceList";

const authProtectedRoutes = [
  { path: "/assignments", component: <TaskList /> },
  { path: "/assignments/:id/:sId", component: <TaskDetails /> },
  { path: "/users", role: "SD", component: <UserList /> },
  { path: "/users/:id", role: "SD", component: <UserDetails /> },
  { path: "/services", role: "SD", component: <ServiceList /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/assignments" />,
  },
  { path: "*", component: <Navigate to="/assignments" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
];

export { authProtectedRoutes, publicRoutes };
