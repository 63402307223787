import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getServices as getServicesApi,
  upsertService as upsertServiceApi,
} from "../../common/services/service_service";

export const getServices = createAsyncThunk(
  "services/getServices",
  async () => {
    try {
      const response = getServicesApi();
      return response;
    } catch (error) {
      toast.error("Get Services Failed", { autoClose: 3000 });
      throw error;
    }
  }
);

export const upsertService = createAsyncThunk(
  "services/upsertService",
  async (service) => {
    try {
      const response = await upsertServiceApi(service);
      return response;
    } catch (error) {
      toast.error("Service Creation/Update Failed", { autoClose: 3000 });
      throw error;
    }
  }
);
