import React, { useCallback, useEffect, useState } from "react";
import * as moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form,
  Button,
  Badge,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

// Formik
import { FieldArray, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import CustomInput from "./Form/CustomInput";
import CustomSelect from "./Form/CustomSelect";
import CustomDatePicker from "./Form/CustomDatePicker";
import Loader from "Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

import {
  updateTask,
  createFloatTask,
  createTeamsTask,
  getTask,
} from "slices/tasks/thunk";
import {
  assignmentStates,
  invoiceStates as invoiceStatesEnum,
} from "Components/constants/assignmentStates";
import { TaskStatus } from "../TaskList/Enum";
import AutoSave from "./Form/AutoSave";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const AssignmentDetails = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [allUsers, setAllUsers] = useState([]);
  const [states, setStates] = useState([]);
  const [invoiceStates, setInvoiceStates] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [reportReceiver, setReportReceiver] = useState({
    email: "",
    phone: "",
  });

  const [hasPermission, setHasPermission] = useState(false);

  const {
    task,
    isTaskSuccess,
    error,
    users,
    currentUser: user,
  } = useSelector((state) => ({
    task: state.Tasks.task,
    isTaskSuccess: state.Tasks.isTaskSuccess,
    error: state.Tasks.error,
    users: state.Users.userList,
    currentUser: state.Users.currentUser,
  }));

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const dispatch = useDispatch();

  const updateAssignment = (obj) => {
    const values = JSON.parse(JSON.stringify(obj));

    delete values.assignment;
    delete values.service;

    values.status = Number(values.status);

    if (values.bookingStatus === "") {
      values.bookingStatus = null;
    }

    if (values.bookingStatus === "0") {
      values.bookingStatus = 0;
    }

    if (values.bookingStatus === "1") {
      values.bookingStatus = 1;
    }

    if (values.reportRecieverId === "") {
      values.reportRecieverId = null;
    }

    if (values.technicalScopingResponsibleEmail === "") {
      values.technicalScopingResponsibleEmail = null;
    }

    values.debriefingRequired = strParseJson(values.debriefingRequired);
    values.informationSentToSA = strParseJson(values.informationSentToSA);
    values.prerequisitesMailSent = strParseJson(values.prerequisitesMailSent);

    values.invoiceStatus = Number(values.invoiceStatus);

    if (values.peerQAEmail === "") values.peerQAEmail = null;

    dispatch(updateTask(values));
  };

  const formValues = useFormik({
    enableReinitialize: true,
    initialValues: task ?? {},
    onSubmit: (obj) => {
      updateAssignment(obj);
    },
  });

  const strParseJson = (val) => {
    if (typeof val === "boolean") {
      return val;
    }
    return !val ? null : JSON.parse(val);
  };

  const archiveAssignment = () => {
    formValues.setFieldValue("status", TaskStatus.Archived);
  };

  useEffect(() => {
    if (formValues.values.members) {
      let membersEmails = formValues.values.members.map((x) => x.user.email);
      if (task?.assignment?.responsibleBDM?.email) {
        membersEmails.push(task?.assignment?.responsibleBDM?.email);
      }
      setAllUsers(users.filter((x) => !membersEmails.includes(x.email)));
    }
  }, [users, formValues.values.members]);

  useEffect(() => {
    if (task && user) {
      setHasPermission(task.status != TaskStatus.Potential);

      // Set report receiver
      let reportReceiver = task?.assignment?.customer?.contacts?.find(
        (x) => x.id === task?.reportRecieverId
      );

      if (reportReceiver == null) {
        reportReceiver = {
          email: "",
          phone: "",
        };
      }

      setReportReceiver(reportReceiver);

      if (user.primaryRole == "SD" || user.primaryRole == "I") {
        setInvoiceStates(invoiceStatesEnum);
      } else if (user.primaryRole == "BDM" && task.invoiceStatus == 2) {
        setInvoiceStates(
          invoiceStatesEnum.filter((x) => x.id <= task.invoiceStatus)
        );
      } else {
        setInvoiceStates(
          invoiceStatesEnum.filter((x) => x.id == task.invoiceStatus)
        );
      }

      // Restrict permissions
      if (task.status < TaskStatus.Execution || user.primaryRole == "BDM") {
        setStates(assignmentStates.filter((x) => x.id == task.status));
        return;
      }

      setStates(assignmentStates);

      // Restrict permissions
      if (user) {
        // Lost & Archived only used in TaskList
        var filteredStates = assignmentStates.filter(
          (x) =>
            (x.id != TaskStatus.Lost &&
              x.id != TaskStatus.Archived &&
              x.id >= task?.status) ||
            x.id == task?.status
        );

        if (user.primaryRole == "SA" || user.primaryRole == "M") {
          // Managers
          filteredStates = assignmentStates.filter(
            (x) =>
              ((x.id == TaskStatus.Execution ||
                x.id == TaskStatus.QA ||
                x.id == TaskStatus.ReportReadyForDelivery ||
                x.id == TaskStatus.ReportDelivered) &&
                x.id >= task.status) ||
              x.id == task.status
          );

          // SA
          if (user.primaryRole == "SA")
            filteredStates = filteredStates.filter(
              (x) =>
                ((x.id == TaskStatus.Execution ||
                  x.id == TaskStatus.QA ||
                  x.id == TaskStatus.ReportReadyForDelivery) &&
                  x.id >= task.status) ||
                x.id == task.status
            );
        }

        setStates(filteredStates);
      }
    }
  }, [task, user]);

  function reformatDate(val) {
    const dateString = val[0];

    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Get the year, month, and day from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  return (
    <React.Fragment>
      <BreadCrumb
        title={`${
          task?.name ??
          `${task?.assignment?.name} (${task?.assignment?.agreementNumber})`
        }`}
        pageTitle="Assignment"
        link="/assignments"
      />
      <Row>
        <Col lg={3}>
          <Card className="mb-3 border-bottom-0">
            <CardBody>
              <div className="mb-3">
                <h6 className="card-titleflex-grow-1 text-start">Details</h6>
              </div>
              <div className="mb-4">
                <FormikProvider value={formValues}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formValues.handleSubmit();
                      return false;
                    }}
                  >
                    <CustomSelect
                      label="Status"
                      name="status"
                      disabled={!hasPermission || task?.status < 4}
                    >
                      {states.map((state, key) => (
                        <React.Fragment key={key}>
                          <option value={state.id}>{state.name}</option>
                        </React.Fragment>
                      ))}
                    </CustomSelect>
                  </Form>
                </FormikProvider>
              </div>
              <div className="table-card">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td className="fw-medium">Name (original)</td>
                      <td>{task?.assignment?.name}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Service</td>
                      <td>{task?.service?.name}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Agreement number</td>
                      <td>{task?.assignment?.agreementNumber}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Customer</td>
                      <td>{task?.assignment?.customer?.name}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Primary Contact</td>
                      <td>
                        {task?.assignment?.primaryContact?.name} (
                        <a
                          href={`mailto:${task?.assignment?.primaryContact?.email}`}
                        >
                          {task?.assignment?.primaryContact?.email}
                        </a>
                        )
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Price</td>
                      <td>{task?.assignment?.price} DKK</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Delivery Date</td>
                      <td>
                        {moment(
                          task?.startDate ?? task?.assignment?.deliveryDate
                        ).format("DD. MMM Y")}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Opportunity Source</td>
                      <td>{task?.assignment?.opportunitySource}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Reponsible BDM</td>
                      <td>{task?.assignment?.responsibleBDM?.name}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Created by</td>
                      <td>{task?.assignment?.createdBy}</td>
                    </tr>

                    <tr>
                      <td className="fw-medium">Created</td>
                      <td>
                        {moment(task?.assignment?.createdOn).format(
                          "DD. MMM Y HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Updated</td>
                      <td>
                        {moment(task?.assignment?.updatedOn).format(
                          "DD. MMM Y HH:mm"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h6 className="card-title mb-3 flex-grow-1 text-start">Float</h6>

              {task?.floatTaskId ? (
                <>
                  <table className="table mb-3">
                    <tbody>
                      <tr>
                        <td className="fw-medium">Booking status</td>
                        <td>
                          {task.bookingStatus == 0 ? "Final" : "Tentative"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Assignment start</td>
                        <td>{moment(task?.startDate).format("DD. MMM Y")}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Assignment end</td>
                        <td>{moment(task?.endDate).format("DD. MMM Y")}</td>
                      </tr>
                    </tbody>
                  </table>
                  <a
                    className="btn-light btn btn-secondary d-block w-100 mb-3"
                    href={`${process.env.REACT_APP_FLOAT_URL}/#task-${task?.floatTaskId}`}
                    target="_blank"
                  >
                    View in Float
                  </a>
                </>
              ) : (
                <>
                  <div className="d-block">
                    <Button
                      type="submit"
                      className="btn btn-success"
                      block
                      onClick={() => {
                        if (
                          confirm(
                            `Are you sure you want to create a Float booking: "${formValues.values.name}" ?`
                          )
                        ) {
                          dispatch(
                            createFloatTask({
                              name: formValues.values.name,
                              assignmentId: task?.assignmentId,
                              serviceId: task?.serviceId,
                            })
                          );
                        }
                      }}
                      disabled={user.primaryRole != "SD"}
                    >
                      Create: Float booking
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
          {user.primaryRole == "SD" ? (
            <Card>
              <CardBody>
                <h6 className="card-title mb-3 flex-grow-1 text-start">
                  Settings
                </h6>

                <div className="d-block">
                  <Button
                    type="submit"
                    className="btn btn-danger"
                    block
                    onClick={() => {
                      if (
                        confirm(
                          `Are you sure you want to archive: "${formValues.values.name}" ?`
                        )
                      ) {
                        archiveAssignment();
                      }
                    }}
                    disabled={
                      task?.status == TaskStatus.Archived ||
                      user.primaryRole != "SD"
                    }
                  >
                    Archive
                  </Button>
                </div>
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
        </Col>
        <Col lg={9}>
          {isTaskSuccess && task ? (
            <FormikProvider value={formValues}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formValues.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  <CardHeader>
                    <div>
                      <Nav
                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            Booking
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2");
                            }}
                            disabled={task?.teamsId == null}
                          >
                            Members
                            <Badge className="bg-success mx-1">
                              {formValues.values.members
                                ? formValues.values.members.length + 1
                                : 1}
                            </Badge>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggleTab("4");
                            }}
                            disabled={!hasPermission}
                          >
                            QA
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              toggleTab("5");
                            }}
                            disabled={!hasPermission}
                          >
                            Debriefing
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeTab === "6",
                            })}
                            onClick={() => {
                              toggleTab("6");
                            }}
                            disabled={!hasPermission}
                          >
                            Invoice
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ marginLeft: "auto" }}>
                          <NavLink>
                            <AutoSave debounceMs={3000} />
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3 pb-2">
                              <CustomInput
                                label="Name"
                                name="name"
                                type="textarea"
                                rows="1"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Booking status (*)"
                                name="bookingStatus"
                                id="bookingStatus-field"
                                disabled
                              >
                                <option value="">TBD</option>
                                <option value="1">Tentative</option>
                                <option value="0">Final</option>
                              </CustomSelect>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Prerequisites mail sent"
                                name="prerequisitesMailSent"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              >
                                <option value="">TBD</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </CustomSelect>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Information sent to SA"
                                name="informationSentToSA"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              >
                                <option value="">TBD</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </CustomSelect>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Technical scoping responsible (*)"
                                name="technicalScopingResponsibleEmail"
                                disabled={user.primaryRole != "SD"}
                              >
                                <option value="">Choose</option>
                                {users?.map((user, key) => (
                                  <React.Fragment key={key}>
                                    <option
                                      key={key}
                                      value={user.email}
                                      disabled={!user.active}
                                    >
                                      {user.primaryRole
                                        ? `[${user.primaryRole}] `
                                        : "[UNKNOWN] "}
                                      {user.name} ({user.email}){" "}
                                      {!user.active ? "- inactive" : ""}
                                    </option>
                                  </React.Fragment>
                                ))}
                              </CustomSelect>
                            </div>
                          </Col>
                          <hr className="mt-3 mb-4"></hr>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Report receiver"
                                name="reportRecieverId"
                                id="reportReciever-field"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              >
                                <option value="">Choose</option>
                                {task?.assignment?.customer?.contacts?.map(
                                  (contact, key) => (
                                    <React.Fragment key={key}>
                                      <option value={contact.id}>
                                        {contact.name} ({contact.email}){" "}
                                        {contact.active == false
                                          ? " - inactive"
                                          : ""}
                                      </option>
                                    </React.Fragment>
                                  )
                                )}
                              </CustomSelect>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <label>Email</label>
                              <div className="input-group">
                                <Input
                                  type="text"
                                  className="form-control"
                                  label="Email"
                                  name="contactEmail"
                                  value={reportReceiver?.email}
                                  disabled
                                />
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      reportReceiver?.email
                                    );
                                  }}
                                >
                                  <FeatherIcon icon="copy" />
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <label>Mobile phone</label>
                              <div className="input-group">
                                <Input
                                  type="text"
                                  className="form-control"
                                  label="Phone"
                                  name="contactPhone"
                                  value={reportReceiver?.phone}
                                  disabled
                                />
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      reportReceiver?.phone
                                    );
                                  }}
                                >
                                  <FeatherIcon icon="copy" />
                                </button>
                              </div>
                            </div>
                          </Col>
                          <hr className="mt-3 mb-4"></hr>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomSelect
                                label="Debriefing Required"
                                name="debriefingRequired"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              >
                                <option value="">TBD</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </CustomSelect>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomDatePicker
                                label="Deadline for Delivery"
                                name="deadlineForDelivery"
                                onChange={(val) => {
                                  formValues.setFieldValue(
                                    "deadlineForDelivery",
                                    reformatDate(val)
                                  );
                                }}
                                disabled={
                                  user.primaryRole != "SD" ||
                                  (task?.status != TaskStatus.Execution &&
                                    task?.status != TaskStatus.QA)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col lg={12}>
                            {task?.teamsId == null ? (
                              <>
                                <ul className="list-unstyled vstack gap-3">
                                  <li>
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <div className="avatar-sm avatar-group-item">
                                          <div className="avatar-title bg-soft-info text-info rounded-circle">
                                            {
                                              task?.assignment?.responsibleBDM
                                                ?.primaryRole
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-1">
                                          {
                                            task?.assignment?.responsibleBDM
                                              ?.name
                                          }
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {
                                            task?.assignment?.responsibleBDM
                                              ?.jobTitle
                                          }
                                        </p>
                                      </div>
                                      <div className="flex-shrink-0"></div>
                                    </div>
                                  </li>
                                </ul>
                                <Button
                                  type="submit"
                                  className="btn btn-success"
                                  block
                                  onClick={() => {
                                    dispatch(
                                      // @ts-ignore
                                      createTeamsTask({
                                        assignmentId: task?.assignmentId,
                                        serviceId: task?.serviceId,
                                      })
                                    );
                                  }}
                                  disabled={
                                    !hasPermission || user.primaryRole != "SD"
                                  }
                                >
                                  Create: Team channel
                                </Button>
                              </>
                            ) : (
                              <div className="mb-3 pb-2">
                                <p>
                                  Teams:{" "}
                                  <b>{`${
                                    task?.name ??
                                    `${task?.assignment.name} (${task?.assignment.agreementNumber})`
                                  }`}</b>
                                </p>
                                <select
                                  value={selectedTeamMember}
                                  className="form-select mb-3"
                                  onChange={(val) => {
                                    if (val.target.value == "") return;

                                    const currentMembers = [
                                      ...formValues.values.members,
                                    ];

                                    const user = allUsers.find(
                                      (x) => x.email == val.target.value
                                    );

                                    const member = {
                                      assignmentServiceAssignmentId:
                                        formValues.values.assignmentId,
                                      assignmentServiceServiceId:
                                        formValues.values.serviceId,
                                      userEmail: user.email,
                                      user: user,
                                    };

                                    if (currentMembers && user)
                                      currentMembers.push(member);

                                    formValues.setFieldValue(
                                      "members",
                                      currentMembers
                                    );

                                    // Default value
                                    setSelectedTeamMember("");
                                  }}
                                  disabled={
                                    user.primaryRole != "SD" &&
                                    user.primaryRole != "M"
                                  }
                                >
                                  <option value="">Select member</option>
                                  {allUsers?.map((user, index) => (
                                    <option
                                      key={index}
                                      value={user.email}
                                      disabled={!user.active}
                                    >
                                      {user.primaryRole
                                        ? `[${user.primaryRole}] `
                                        : "[UNKNOWN] "}
                                      {user.name} ({user.email}){" "}
                                      {!user.active ? "- inactive" : ""}
                                    </option>
                                  ))}
                                </select>
                                <ul className="list-unstyled vstack gap-3 mb-0">
                                  <li>
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <div className="avatar-sm avatar-group-item">
                                          <div className="avatar-title bg-soft-info text-info rounded-circle">
                                            {
                                              task?.assignment?.responsibleBDM
                                                ?.primaryRole
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-1">
                                          {
                                            task?.assignment?.responsibleBDM
                                              ?.name
                                          }
                                        </h6>
                                        <p className="text-muted mb-0">
                                          {
                                            task?.assignment?.responsibleBDM
                                              ?.jobTitle
                                          }
                                        </p>
                                      </div>
                                      <div className="flex-shrink-0"></div>
                                    </div>
                                  </li>
                                  <FieldArray
                                    name="members"
                                    render={(arrayHelpers) => (
                                      <>
                                        {formValues.values?.members ? (
                                          formValues.values.members.map(
                                            (member, index) => (
                                              <li key={index}>
                                                <div className="d-flex align-items-center">
                                                  <div className="flex-shrink-0">
                                                    <div className="avatar-sm avatar-group-item">
                                                      <div className="avatar-title bg-soft-info text-info rounded-circle">
                                                        {
                                                          member.user
                                                            .primaryRole
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex-grow-1 ms-2">
                                                    <h6 className="mb-1">
                                                      {member.user.name}
                                                    </h6>
                                                    <p className="text-muted mb-0">
                                                      {member.user.jobTitle}
                                                    </p>
                                                  </div>
                                                  <div className="flex-shrink-0">
                                                    <Button
                                                      type="button"
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index
                                                        );
                                                      }}
                                                      className="btn-light btn btn-secondary"
                                                      disabled={
                                                        user.primaryRole !=
                                                          "SD" &&
                                                        user.primaryRole != "M"
                                                      }
                                                    >
                                                      Remove
                                                    </Button>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  />
                                </ul>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Col lg={12}>
                          <div className="mb-3">
                            <CustomSelect
                              label="Peer QA"
                              name="peerQAEmail"
                              disabled={
                                !hasPermission ||
                                user.primaryRole != "M" ||
                                task.status != TaskStatus.QA
                              }
                            >
                              <option value="">Choose</option>
                              {users?.map((user, key) => (
                                <React.Fragment key={key}>
                                  <option
                                    key={key}
                                    value={user.email}
                                    disabled={!user.active}
                                  >
                                    {user.primaryRole
                                      ? `[${user.primaryRole}] `
                                      : "[UNKNOWN] "}
                                    {user.name} ({user.email}){" "}
                                    {!user.active ? "- inactive" : ""}
                                  </option>
                                </React.Fragment>
                              ))}
                            </CustomSelect>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3 pb-2">
                            <CustomInput
                              label="QA Notes"
                              name="qaNotes"
                              type="textarea"
                              rows="3"
                              disabled={
                                !hasPermission ||
                                task?.status < 4 ||
                                user.primaryRole == "BDM"
                              }
                            />
                          </div>
                        </Col>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col lg={12} className="mb-3">
                            <CustomDatePicker
                              label="Date"
                              name="debriefingDate"
                              onChange={(val) => {
                                formValues.setFieldValue(
                                  "debriefingDate",
                                  reformatDate(val)
                                );
                              }}
                              disabled={
                                !hasPermission || user.primaryRole != "SD"
                              }
                            />
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3 pb-2">
                              <CustomInput
                                label="Comments"
                                name="debriefingComments"
                                type="textarea"
                                rows="3"
                                disabled={
                                  !hasPermission || user.primaryRole != "SD"
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col lg={12} className="mb-3">
                            <CustomSelect
                              label="Invoice status"
                              name="invoiceStatus"
                              disabled={
                                !hasPermission ||
                                invoiceStates.length <= 1 ||
                                (user.primaryRole != "SD" &&
                                  user.primaryRole != "I" &&
                                  user.primaryRole != "BDM")
                              }
                            >
                              {invoiceStates.map((state, key) => (
                                <React.Fragment key={key}>
                                  <option value={state.id}>{state.name}</option>
                                </React.Fragment>
                              ))}
                            </CustomSelect>
                          </Col>
                          <Col lg={12} className="mb-3">
                            <CustomInput
                              label="Details"
                              name="invoiceDetails"
                              type="textarea"
                              rows="3"
                              disabled={
                                !hasPermission ||
                                (user.primaryRole != "SD" &&
                                  user.primaryRole != "I" &&
                                  user.primaryRole != "BDM")
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <Col lg={12} className="mb-3">
                  <div className="hstack gap-2 justify-content-end">
                    <Link to="/tasks/" className="btn-light btn btn-secondary">
                      Back
                    </Link>
                  </div>
                </Col>
              </Form>
            </FormikProvider>
          ) : (
            <Loader error={error} />
          )}
        </Col>
      </Row>

      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default AssignmentDetails;
