export const TaskStatus = {
  Potential: 0,
  New: 1,
  Planned: 2,
  Booked: 3,
  Execution: 4,
  QA: 5,
  ReportReadyForDelivery: 6,
  ReportDelivered: 7,
  PresentationPlanned: 8,
  AwaitingInvoicing: 9,
  AwaitingFeedback: 10,
  Completed: 11,
  Lost: 12,
  Archived: 13,
};

export const InvoiceStatus = {
  AwaitingInvoicer: 1,
  AwaitingBDM: 2,
  InvoiceDetailsCompleted: 3,
  AwaitingInvoicing: 4,
  Invoiced: 5,
};
