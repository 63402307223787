import { combineReducers } from "redux";

// Service
import ServicesReducer from "./services/reducer";

// Users
import UsersReducer from "./users/reducer";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Tasks
import TasksReducer from "./tasks/reducer";

const rootReducer = combineReducers({
  Users: UsersReducer,
  Layout: LayoutReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  Tasks: TasksReducer,
  Services: ServicesReducer,
});

export default rootReducer;
