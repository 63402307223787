import { useField } from "formik";
import React from "react";
import { FormFeedback, Label } from "reactstrap";

const CustomSelect = ({ label, ...props }) => {
  let [field, meta] = useField(props);

  // Select value can't be null
  if (field.value == null) field.value = "";

  return (
    <>
      <Label className="form-label">
        {label}
        {props.required ? <span className="text-danger"> *</span> : ""}
      </Label>
      <select
        {...field}
        {...props}
        className={
          meta.touched && meta.error ? "is-invalid form-select" : "form-select"
        }
      />

      {meta.touched && meta.error && (
        <FormFeedback type="invalid">{meta.error}</FormFeedback>
      )}
    </>
  );
};
export default CustomSelect;
