import { useField } from "formik";
import React from "react";
import { FormFeedback, Input, Label } from "reactstrap";

const CustomInput = ({ label, ...props }) => {
  let [field, meta] = useField(props);

  if (field.value == null) field.value = "";

  return (
    <>
      <Label>{label}</Label>
      <Input
        {...field}
        {...props}
        invalid={meta.touched && meta.error ? true : false}
      ></Input>
      {meta.touched && meta.error ? (
        <FormFeedback type="invalid">{meta.error}</FormFeedback>
      ) : null}
    </>
  );
};
export default CustomInput;
