import { createSlice } from "@reduxjs/toolkit";
import {
  getTask,
  getTaskList,
  addNewTask,
  updateTask,
  deleteTask,
  createFloatTask,
  createTeamsTask,
} from "./thunk";

import { TaskStatus } from "../../pages/Tasks/TaskList/Enum";

export const initialState = {
  taskList: [],
  allTasks: [],
  task: null,
  filter: { status: null },
};

const TasksSlice = createSlice({
  name: "TasksSlice",
  initialState,
  reducers: {
    updateFilterAction(state, action) {
      if (
        action.payload.bookingStatus.length > 0 ||
        action.payload.invoiceStatus.length > 0
      ) {
        const filter = action.payload.bookingStatus.map((x) => x.value);
        const invoiceFilter = action.payload.invoiceStatus.map((x) => x.value);

        state.taskList = state.allTasks.filter(
          (x) =>
            filter.includes(x.status) && invoiceFilter.includes(x.invoiceStatus)
        );
      } else {
        state.taskList = state.allTasks;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.task = action.payload;
      state.isTaskSuccess = true;
    });
    builder.addCase(getTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskSuccess = false;
    });
    builder.addCase(getTaskList.fulfilled, (state, action) => {
      state.allTasks = action.payload;
      if (state.allTasks) {
        state.taskList = state.allTasks.filter(
          (x) => x.status < TaskStatus.Lost
        );
      }
      state.isTaskListSuccess = true;
    });
    builder.addCase(getTaskList.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskListSuccess = false;
    });
    builder.addCase(addNewTask.fulfilled, (state, action) => {
      state.taskList.push(action.payload.data);
      state.isTaskCreated = true;
      state.isTaskAdd = true;
      state.isTaskAddFail = false;
    });
    builder.addCase(addNewTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskAdd = false;
      state.isTaskAddFail = true;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      state.task = action.payload;
      state.isTaskUpdate = true;
      state.isTaskUpdateFail = false;
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskUpdate = false;
      state.isTaskUpdateFail = true;
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.taskList = state.taskList.filter(
        (task) => task._id.toString() !== action.payload.task.toString()
      );
      state.isTaskDelete = true;
      state.isTaskDeleteFail = false;
    });
    builder.addCase(deleteTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskDelete = false;
      state.isTaskDeleteFail = true;
    });

    builder.addCase(createFloatTask.fulfilled, (state, action) => {
      state.task = action.payload;
      state.isTaskSuccess = true;
    });
    builder.addCase(createFloatTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskSuccess = false;
    });

    builder.addCase(createTeamsTask.fulfilled, (state, action) => {
      state.task.teamsId = action.payload;
      state.isTaskSuccess = true;
    });
    builder.addCase(createTeamsTask.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.isTaskSuccess = false;
    });
  },
});

export const { updateFilterAction } = TasksSlice.actions;

export default TasksSlice.reducer;
