import TableContainer from "Components/Common/TableContainer";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Col } from "reactstrap";
import { getUsers } from "slices/users/thunk";

const AllUsers = () => {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => ({
    users: state.Users.userList,
  }));

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellProps) => {
        return (
          <React.Fragment>
            <div className="d-flex">
              <div className="flex-grow-1 tasks_name">
                <Link to={`/users/${cellProps.row?.original?.email}`}>
                  {cellProps.row?.original.name
                    ? cellProps.row?.original.name
                    : cellProps.row?.original.assignment.name}
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (cellProps) => {
        return (
          <React.Fragment>
            <div className="d-flex">
              <div className="flex-grow-1">{cellProps.value}</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Role",
      accessor: "primaryRole",
      Cell: (cellProps) => {
        return (
          <React.Fragment>
            <div className="d-flex">
              <div className="flex-grow-1">{cellProps.value}</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: (
        <>
          <div style={{ textAlign: "center" }}>Active</div>
        </>
      ),
      accessor: "active",
      Cell: (cellProps) => {
        return (
          <React.Fragment>
            <div className="d-flex">
              <div className="flex-grow-1" style={{ textAlign: "center" }}>
                {cellProps.value ? (
                  <FeatherIcon icon="check" />
                ) : (
                  <FeatherIcon color="red" icon="x" />
                )}
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
  ]);

  return (
    <React.Fragment>
      <div className="row">
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">All Users</h5>
              </div>
            </div>

            <div className="card-body pt-0">
              <TableContainer
                columns={columns}
                data={users || []}
                isGlobalFilter={false}
                customPageSize={50}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
                trClass=""
                isTaskListFilter={true}
              />
              <ToastContainer closeButton={false} limit={1} />
            </div>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default AllUsers;
