import TableContainer from "Components/Common/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Col } from "reactstrap";
import { getServices, upsertService } from "slices/services/thunk";

const AllServices = () => {
  const dispatch = useDispatch();

  const { serviceList } = useSelector((state) => ({
    serviceList: state.Services.serviceList,
  }));

  useEffect(() => {
    dispatch(getServices());
  }, []);

  // Select single checkbox
  const selectCheckbox = (checkbox) => {
    if (checkbox) {
      let servicePayload = {
        id: checkbox.value,
        active: checkbox.checked,
      };
      dispatch(upsertService(servicePayload));
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      filterable: false,
      Cell: (cellProps) => {
        return <React.Fragment>{cellProps.value}</React.Fragment>;
      },
    },
    {
      Header: (
        <>
          <div style={{ textAlign: "right" }}>Active</div>
        </>
      ),
      width: 20,
      Cell: (cellProps) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <input
                type="checkbox"
                className="taskCheckBox form-check-input"
                value={cellProps.row?.original?.id}
                defaultChecked={cellProps.row?.original.active}
                onChange={(e) => selectCheckbox(e.target)}
              />
            </div>
          </>
        );
      },
      id: "#",
    },
  ];

  return (
    <React.Fragment>
      <div className="row">
        <Col lg={12}>
          <div className="card" id="serviceList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">All Services</h5>
              </div>
            </div>

            <div className="card-body pt-0">
              <TableContainer
                columns={columns}
                data={serviceList || []}
                isGlobalFilter={false}
                customPageSize={50}
                divClass="table-responsive table-card mb-3"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
                trClass=""
                isTaskListFilter={true}
              />
              <ToastContainer closeButton={false} limit={1} />
            </div>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default AllServices;
