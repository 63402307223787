import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { setAuthorization } from "helpers/api_helper";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "slices/users/thunk";

export default function AzureAD() {
  const dispatch = useDispatch();

  // Redirect to Azure AD if not logged in
  useMsalAuthentication(InteractionType.Redirect);

  const { instance, accounts } = useMsal();

  // MIA - AD Settings
  useEffect(() => {
    var account = accounts[0];
    if (account) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          setAuthorization(response.accessToken);

          // Get current user
          dispatch(getCurrentUser());
        });
    }
  }, [accounts]);

  return <></>;
}
