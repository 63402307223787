import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const AutoSave = ({ debounceMs = 1000 }) => {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(true);

  useEffect(() => {
    if (formik.dirty) {
      setIsSaved(false);
      const t = setTimeout(() => {
        formik.submitForm().then(() => setIsSaved(true));
      }, debounceMs);

      return () => {
        clearTimeout(t);
      };
    }
  }, [formik.values]);

  return (
    <span className="text-success">
      {!isSaved ? (
        <>Saving ...</>
      ) : isSaved ? (
        <>
          <FeatherIcon icon="check" className="text-success" size="18" />
        </>
      ) : null}
    </span>
  );
};

export default AutoSave;
