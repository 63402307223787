import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { updateFilterAction } from "./reducer";

//Include Both Helper File with needed methods
import {
  getTask as getTaskApi,
  getTaskList as getTaskListApi,
  addNewTask as addNewTaskApi,
  updateTask as updateTaskApi,
  deleteTask as deleteTaskApi,
  createFloatTask as createFloatTaskApi,
  createTeamsTask as createTeamsTaskApi,
} from "../../helpers/fakebackend_helper";

export const updateFilter = (payload) => async (dispatch) => {
  try {
    dispatch(updateFilterAction(payload));
  } catch (error) {}
};

export const getTask = createAsyncThunk("tasks/getTask", async (payload) => {
  try {
    const response = await getTaskApi(payload.assignmentId, payload.serviceId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getTaskList = createAsyncThunk("tasks/getTaskList", async () => {
  try {
    const response = await getTaskListApi();
    return response;
  } catch (error) {
    toast.error("Get Tasks Failed", { autoClose: 3000 });

    throw error;
  }
});

export const addNewTask = createAsyncThunk("tasks/addNewTask", async (task) => {
  try {
    const response = await addNewTaskApi(task);
    toast.success("Task Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Task Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const updateTask = createAsyncThunk("tasks/updateTask", async (task) => {
  try {
    const response = await updateTaskApi(task);
    return response;
  } catch (error) {
    toast.error("Task Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const deleteTask = createAsyncThunk("tasks/deleteTask", async (task) => {
  try {
    const response = await deleteTaskApi(task);
    toast.success("Task Updated Successfully", { autoClose: 3000 });
    return { task, ...response };
  } catch (error) {
    toast.error("Task Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const createFloatTask = createAsyncThunk(
  "tasks/createFloatTask",
  async (task) => {
    try {
      const response = await createFloatTaskApi(task);
      toast.success("Float Task Added Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Float Task Added Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const createTeamsTask = createAsyncThunk(
  "tasks/createTeamsTask",
  async (task) => {
    try {
      const response = await createTeamsTaskApi(task);
      toast.success("Teams Added Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Teams Task Added Failed", { autoClose: 3000 });
      return error;
    }
  }
);
