import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "slices/users/thunk";
import UserInfo from "./UserInfo";
import { getServices } from "slices/services/thunk";
import { ToastContainer } from "react-toastify";

const UserDetails = () => {
  document.title = "User Details | ImproView";

  const dispatch = useDispatch();

  let { id } = useParams();

  useEffect(() => {
    dispatch(getUser({ email: id }));
    dispatch(getServices());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <UserInfo></UserInfo>
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserDetails;
