import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCurrentUser as getCurrentUserApi,
  getUser as getUserApi,
  getUsers as getUsersApi,
  upsertUser as upsertUserApi,
} from "../../common/services/user_service";

export const getCurrentUser = createAsyncThunk(
  "users/getCurrentUser",
  async () => {
    try {
      const response = getCurrentUserApi();
      return response;
    } catch (error) {
      toast.error("Get Current User Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const getUser = createAsyncThunk("users/getUser", async (payload) => {
  try {
    const response = getUserApi(payload.email);
    return response;
  } catch (error) {
    toast.error("Get Users Failed", { autoClose: 3000 });
    return error;
  }
});

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  try {
    const response = getUsersApi();
    return response;
  } catch (error) {
    toast.error("Get Users Failed", { autoClose: 3000 });
    return error;
  }
});

export const upsertUser = createAsyncThunk("users/upsertUser", async (user) => {
  try {
    const response = await upsertUserApi(user);
    toast.success("User Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("User Creation/Update Failed", { autoClose: 3000 });
    return error;
  }
});
