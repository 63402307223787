import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, getUser, getUsers, upsertUser } from "./thunk";
export const initialState = {
  userList: [],
  currentUser: null,
  user: null,
};

const UsersSlice = createSlice({
  name: "UsersSlice",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.error = action.error?.message || null;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(upsertUser.fulfilled, (state, action) => {
      state.userList.push(action.payload);
      state.isUserCreated = true;
      state.isUserAdd = true;
      state.isUserAddFail = false;
    });
    builder.addCase(upsertUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.isUserAdd = false;
      state.isUserAddFail = true;
    });
  },
});

export default UsersSlice.reducer;
