import React, { useEffect } from "react";
import { Container } from "reactstrap";

import AssignmentDetails from "./AssignmentDetails";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTask } from "slices/tasks/thunk";
import { getUsers } from "slices/users/thunk";

const TaskDetails = () => {
  document.title = "Assignment Details | ImproView";

  const dispatch = useDispatch();

  const { users } = useSelector((state) => ({
    users: state.Users.userList,
  }));

  let { id, sId } = useParams();

  useEffect(() => {
    dispatch(getTask({ assignmentId: id, serviceId: sId }));

    // Update task every 30 sec
    const interval = setInterval(() => {
      dispatch(getTask({ assignmentId: id, serviceId: sId }));
    }, 30000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(getUsers());
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AssignmentDetails />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TaskDetails;
